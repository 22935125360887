<style>
.video-container {
  width: 50%;
  /* 你可以根据需要调整这个百分比或设置为固定像素值 */
  padding-top: 56.25%;
  /* 这个值应该是宽高比的百分比表示，例如16:9的宽高比，9/16*100=56.25% */
  position: relative;
}

.video-container video {
  position: absolute;
  top: -50px;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* 保持视频的宽高比，同时填充容器 */
}
</style>
<template>
  <div class="IndexBody" ref="scrollContainer">
    <ImgView :ImgWidth="ImgWidth" ImgHeight="1000px">
      <template v-slot:body>
        <div style="position: absolute;left: 200px;top: 200px;">
          <!-- <div style="font-size: 80px;color: #fff;letter-spacing: 5px;font-weight: bold;opacity: 0.8;">易单据</div> -->
        </div>
        <div class="video-container" style="position: absolute;z-index: -1;width: 100%;">
          <video class="videoPaly" style="width: 100%;height: 100%;" autoplay loop muted
            src="https://res-oss.elist.com.cn/web/qyjs.mp4"></video>
        </div>
      </template>
    </ImgView>
    <ImgView :url="IndexUrl[0]" :ImgWidth="ImgWidth" ImgHeight="700px">
      <template v-slot:body>
        <div class="web01">
          <div class="web01_text">
            <div class="web01_text_hander">
              简单易用，快捷上手
            </div>
            <div class="web01_text_t">
              <div class="dian"></div> 一款便捷、高效、准确的电子单据服务平台
            </div>
            <div class="web01_text_t">
              <div class="dian"></div>手机一键开单，轻松管理单据
            </div>
          </div>
          <div style="position: absolute;right: 200px;bottom: 20px;">
            <img src="../../public/web/webOrder.webp" style="width: 500px;height: 500px;">
          </div>
        </div>
      </template>
    </ImgView>
    <WebEmptyView :ImgWidth="ImgWidth" ImgHeight="800px">
      <template v-slot:body>
        <div class="web02">
          <div class="web02_title">
            <div class="web01_text_hander">
              <img src="../../public/app/hj.webp" style="width: 50px;height: 50px;margin-right: 40px;">提高效率，降低成本
            </div>
            <div class="web_text_t">
              <div class="dian"></div>手机一键开单，简化开单流程
            </div>
            <div class="web_text_t">
              <div class="dian"></div>关键词输入检索，快速查询订单
            </div>
            <div class="web_text_t">
              <div class="dian"></div> 订单数据可视化呈现
            </div>
          </div>
          <div style="position: absolute;left: 300px;bottom: 150px;">
            <img src="../../public/web/phone.webp" style="width:360px;height: 500px;">
          </div>
        </div>
      </template>
    </WebEmptyView>
    <ImgView :url="IndexUrl[2]" :ImgWidth="ImgWidth" ImgHeight="900px">
      <template v-slot:body>
        <div class="web03">
          <div class="web03_text">

            <div class="web03_text_hander">
              <img src="../../public/app/fun.webp" style="width: 50px;height: 50px;margin-right: 20px;">
              收发两端，快速切换
            </div>
            <div class="web_text_t3">
              <div class="dian"></div>
              一个账号，两种角色
            </div>
            <div class="web_text_t3">
              <div class="dian"></div>
              通过按钮切换收发端口
            </div>
            <div class="web_text_t3">
              <div class="dian"></div>
              满足用户销售和采买货品双向需求
            </div>
          </div>
          <div style="position: absolute;right: 300px;bottom: 150px;">
            <img src="../../public/web/phone2.webp" style="width:520px;height: 500px;">
          </div>
        </div>
      </template>
    </ImgView>
    <ImgView :url="IndexUrl[3]" :ImgWidth="ImgWidth" ImgHeight="800px">

    </ImgView>
    <ImgView :url="IndexUrl[5]" :ImgWidth="ImgWidth" ImgHeight="800px"></ImgView>
    <!-- <ImgView :url="IndexUrl[6]" :ImgWidth="ImgWidth"></ImgView> -->
    <EndView :url="IndexUrl[6]" :ImgWidth="ImgWidth" @move-click="move"></EndView>
    <!-- EndView -->
  </div>
</template>

<script>
import ImgView from '../modularization/ImgView.vue'
import EndView from '../modularization/EndView.vue'
import WebEmptyView from '../modularization/WebEmptyView.vue'

import { useRouter } from 'vue-router'
export default {
  props: ['ImgWidth'],
  components: {
    ImgView,
    EndView,
    WebEmptyView
  },
  name: 'indexPage',
  data() {
    return {
      TabWidth: "1800px",
      IndexUrl: [
        "../web/001.webp",
        "../web/002.png",
        "../web/003.webp",
        "../web/004.webp",
        "../web/006.png",
        "../web/005.webp",
        "../web/09.png",
      ],
      router: null,
      page: 0
    }
  },
  mounted() {
    const router = useRouter()
    this.router = router;
    console.log("home");

    setTimeout(function () {
      document.querySelector(".videoPaly").play()
    }, 1000)

  },
  methods: {
    MOVEScroll(y) {
      var that = this;
      setTimeout(function () {
        that.$refs.scrollContainer.scrollTop = that.$refs.scrollContainer.scrollHeight * y;
      }, 100)
      console.log(y);

    },
    move(index, y) {
      switch (index) {
        case 0:
          this.$emit('tab-click', index, y);
          break;
        case 1:
          this.$emit('tab-click', index, y);
          break;
        case 2:
          this.$emit('tab-click', index, y);
          break;
        default:
          break;
      }
    }
  }
}
</script>

<style scoped lang="scss">
.IndexBody {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  // margin-top: 60px;

  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.web01 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  position: relative;

  // background-color: aqua;

  .web01_text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: absolute;

    left: 200px;

    .web01_text_hander {
      font-family: Microsoft YaHei;
      font-size: 66px;
      font-weight: bold;
      line-height: normal;
      letter-spacing: 0em;

      font-variation-settings: "opsz" auto;
      color: #333333;

      margin-bottom: 50px;
      width: 1000px;
    }

    .web01_text_t {
      display: flex;
      flex-direction: row;
      justify-content: left;
      align-items: center;

      font-family: Microsoft YaHei;
      font-size: 25px;
      font-weight: normal;
      line-height: normal;
      letter-spacing: 0.01em;

      font-variation-settings: "opsz" auto;
      color: #333333;

      width: 1000px;
      text-align: left;

      margin-bottom: 10px;

      .dian {
        width: 10px;
        height: 10px;
        border-radius: 100px;
        background-color: #01BB74;
        margin-right: 15px;
      }
    }
  }
}


.web_text_t {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;

  font-family: Microsoft YaHei;
  font-size: 25px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0.01em;

  padding-left: 100px;

  font-variation-settings: "opsz" auto;
  color: #333333;


  text-align: left;

  margin-bottom: 10px;

  .dian {
    width: 10px;
    height: 10px;
    border-radius: 100px;
    background-color: #01BB74;
    margin-right: 15px;
  }
}


.web01_text_hander {
  font-family: Microsoft YaHei;
  font-size: 50px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 0em;

  font-variation-settings: "opsz" auto;
  color: #333333;

  margin-bottom: 50px;
  width: 1000px;
}

.web02 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  position: relative;

  .web02_title {
    position: absolute;
    width: 700px;
    right: 300px;
    // background-color: #01BB74;
  }
}


.web03 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  position: relative;

  .web03_text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: absolute;

    left: 250px;

    .web03_text_hander {

      display: flex;
      flex-direction: row;
      justify-content: left;
      align-items: center;

      font-family: Microsoft YaHei;
      font-size: 50px;
      font-weight: bold;
      line-height: normal;
      letter-spacing: 0em;

      font-variation-settings: "opsz" auto;
      color: #333333;

      margin-bottom: 50px;
      width: 800px;
    }


    .web_text_t3 {
      display: flex;
      flex-direction: row;
      justify-content: left;
      align-items: center;

      font-family: Microsoft YaHei;
      font-size: 25px;
      font-weight: normal;
      line-height: normal;
      letter-spacing: 0.01em;

      font-variation-settings: "opsz" auto;
      color: #333333;


      text-align: left;

      margin-bottom: 10px;

      width: 800px;

      padding-left: 120px;

      .dian {
        width: 10px;
        height: 10px;
        border-radius: 100px;
        background-color: #01BB74;
        margin-right: 15px;
      }
    }

  }
}
</style>