<template>
    <div class="echartsChart" v-if="legal">
        <div class="echartsChartView">
            <div id="echartsChartPie"></div>
            <div class="echartsChartViewTitle">
                <div class="echartsChartViewTitleL">总金额
                    <!-- <span style="color: #F53F3F;margin-left: 6px;font-size: 14px;">↓<span style="margin-left: 4px;">0.8%
                        </span></span> -->
                </div>
                <div class="echartsChartViewTitleR" style="color: #01BB74;">￥{{
                    echartsDatabases.threePrice + echartsDatabases.onePrice + echartsDatabases.threePrice }} </div>
            </div>
            <div class="echartsChartViewTitle">
                <div class="echartsChartViewTitleL">
                    <div class="box" style="background-color: #F7A944;"></div>待签收
                </div>
                <div class="echartsChartViewTitleR">￥{{ echartsDatabases.onePrice }}</div>
            </div>
            <div class="echartsChartViewTitle">
                <div class="echartsChartViewTitleL">
                    <div class="box" style="background-color: #418AFF;"></div>已签收
                </div>
                <div class="echartsChartViewTitleR">￥{{ echartsDatabases.twoPrice }} </div>
            </div>
            <div class="echartsChartViewTitle">
                <div class="echartsChartViewTitleL">
                    <div class="box" style="background-color: #01BB74;"></div>已付款
                </div>
                <div class="echartsChartViewTitleR">￥{{ echartsDatabases.threePrice }} </div>
            </div>
        </div>
        <div class="echartsChartView">
            <div class="echartsChartViewTitleName">待签收统计图 </div>
            <div id="echartsChartColumnarOne"></div>
        </div>
        <div class="echartsChartView">
            <div class="echartsChartViewTitleName">已签收统计图 </div>
            <div id="echartsChartColumnarTwo"></div>
        </div>
        <div class="echartsChartView">
            <div class="echartsChartViewTitleName">已收款统计图 </div>
            <div id="echartsChartColumnarThree"></div>
        </div>
    </div>

    <div v-if="!legal" style="text-align: center;align-items: center;padding-top: 100px;">不支持访问</div>

</template>
<script>
import * as echarts from 'echarts';
import axios from 'axios';

export default {
    data() {
        return {
            legal: true,
            informationData: {},
            echartsDatabases: {}
        }
    },
    mounted() {
        this.getCurrentUrlAndParams()
    },
    methods: {
        getCurrentUrlAndParams() {
            // 获取当前网址
            this.currentUrl = window.location.href;

            // 获取查询字符串并解析
            const queryParams = new URLSearchParams(window.location.search);

            // 提取 token 和 phone 参数
            var token = queryParams.get('token')
            var phone = queryParams.get('phone')

            if (token == null && phone == null) {
                this.legal = false
            } else {


                const options = {
                    method: 'POST',
                    url: 'http://localhost:8080/test/edo/echarts/year',
                    headers: { token: token, phone: phone },
                    data: {
                        particularYear: 2024,
                        phone: phone
                    }
                };
                var that = this;
                axios.request(options).then(function (response) {
                    that.informationData = response.data.data.data;
                    that.echartsDatabases = that.informationData.echartsDatabases[0]
                    that.InitPie(that.informationData)


                    var one = []
                    var two = []
                    var three = []

                    that.informationData.cOrderLists.forEach(element => {
                        one.push(element.one)
                        two.push(element.two)
                        three.push(element.three)
                    });



                    that.InitColumnarOne(one)
                    that.InitColumnarTwo(two)
                    that.InitColumnarThree(three)
                }).catch(function (error) {
                    console.error(error);
                });



            }


        },
        InitPie(echartsDatabases) {
            var chartDom = document.getElementById('echartsChartPie');
            var myChart = echarts.init(chartDom);
            var option;

            console.log(echartsDatabases);


            option = {
                tooltip: {
                    trigger: 'item',
                    formatter: function (params) {
                        console.log("金额", params);
                        // 自定义 tooltip 内容
                        return `<div>
                                <strong>金额: ${params.data.price}元</strong><br/>
                                <span>开单数: ${params.value} 单</span>
                            </div>`;
                    }
                },
                series: [
                    {
                        name: 'Access From',
                        type: 'pie',
                        radius: ['30%', '50%'],
                        avoidLabelOverlap: false,
                        itemStyle: {
                            borderRadius: 10,
                            borderColor: '#fff',
                            borderWidth: 2
                        },
                        label: {
                            normal: {
                                textStyle: {
                                    color: '#595959', // 提示文字颜色
                                    fontSize: 12 // 提示文字大小
                                }
                            }
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: 12,
                                fontWeight: '100'
                            }
                        },
                        labelLine: {
                            show: true,
                            length: 10, // 指示线长度

                        },
                        data: [
                            { value: echartsDatabases.echartsDatabases[0].one, name: '待签收', itemStyle: { color: "#F7A944" }, price: echartsDatabases.echartsDatabases[0].onePrice },
                            { value: echartsDatabases.echartsDatabases[0].two, name: '已签收', itemStyle: { color: "#418AFF" }, price: echartsDatabases.echartsDatabases[0].twoPrice },
                            { value: echartsDatabases.echartsDatabases[0].three, name: '已收款', itemStyle: { color: "#01BB74" }, price: echartsDatabases.echartsDatabases[0].threePrice }
                        ]
                    }
                ]
            };

            option && myChart.setOption(option);

        },
        InitColumnarOne(one) {
            var chartDom = document.getElementById('echartsChartColumnarOne');
            var myChart = echarts.init(chartDom);
            var option;

            option = {
                xAxis: {
                    type: 'category',
                    data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        data: one,
                        type: 'bar',
                        itemStyle: {
                            borderRadius: [10, 10, 0, 0], // 设置左上角和右上角的圆角为10，右下角和左下角的圆角为0
                            color: "#F7A944"
                        }
                    }
                ]
            };

            option && myChart.setOption(option);
        },
        InitColumnarTwo(two) {
            var chartDom = document.getElementById('echartsChartColumnarTwo');
            var myChart = echarts.init(chartDom);
            var option;

            option = {
                xAxis: {
                    type: 'category',
                    data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        data: two,
                        type: 'bar',
                        itemStyle: {
                            borderRadius: [10, 10, 0, 0], // 设置左上角和右上角的圆角为10，右下角和左下角的圆角为0
                            color: "#418AFF"
                        }
                    }
                ]
            };

            option && myChart.setOption(option);
        },
        InitColumnarThree(three) {
            var chartDom = document.getElementById('echartsChartColumnarThree');
            var myChart = echarts.init(chartDom);
            var option;

            option = {
                xAxis: {
                    type: 'category',
                    data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        data: three,
                        type: 'bar',
                        itemStyle: {
                            borderRadius: [10, 10, 0, 0], // 设置左上角和右上角的圆角为10，右下角和左下角的圆角为0
                            color: "#01BB74"
                        }
                    }
                ]
            };

            option && myChart.setOption(option);
        },
    }
}
</script>
<style lang="scss">
.echartsChart {
    width: 100vw;
    height: auto;
    background-color: #FFFBF1;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.echartsChartView {
    padding-bottom: 12px;
    background-color: white;
    margin-left: 24px;
    margin-right: 24px;
    margin-bottom: 24px;
    border-radius: 8px;

}

#echartsChartPie {
    width: 400px;
    height: 400px;
}

#echartsChartColumnarOne {
    width: 400px;
    height: 300px;
}

#echartsChartColumnarTwo {
    width: 400px;
    height: 300px;
}

#echartsChartColumnarThree {
    width: 400px;
    height: 300px;
}

.echartsChartViewTitle {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;

    position: relative;
    height: 30px;

    margin-left: 12px;
    margin-right: 12px;

    .box {
        height: 10px;
        width: 10px;
        border-radius: 2px;
        margin-right: 12px;
    }

    .echartsChartViewTitleL {
        position: absolute;
        left: 0;
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
    }

    .echartsChartViewTitleR {
        position: absolute;
        right: 0;
    }
}

.echartsChartViewTitleName {
    font-family: Source Han Sans;
    font-size: 18px;
    font-weight: bold;
    line-height: 21px;
    letter-spacing: 0px;

    font-feature-settings: "kern" on;
    color: #333333;

    padding-top: 24px;
    padding-left: 12px;
    padding-right: 12px;


}
</style>